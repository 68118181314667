import { createTheme } from "@mui/material";

import styles from "../global.scss";

console.log(createTheme())

export const theme = createTheme({
    typography: {
        // fontFamily: "urbanist",
        fontFamily: "Plus Jakarta Sans",
        h1: {
            fontWeight: 900,
            fontSize: "148px",
            color: styles["primary_light"]
        },
        h2: {
            fontWeight: 800,
            fontSize: "108px",
            color: styles["primary_light"]
        },
        h3: {
            fontWeight: 700,
            fontSize: "72px",
            color: styles["primary_light"]
        },
        h4: {
            fontWeight: 600,
            fontSize: "48px",
            color: styles["primary_light"]
        },
        h5: {
            fontWeight: 600,
            fontSize: "32px",
            color: styles["primary_light"]
        },
        h6: {
            fontWeight: 600,
            fontSize: "24px",
            color: styles["primary_light"]
        },
        subtitle1: {
            fontWeight: 600,
            fontSize: "20px",
            color: styles["primary_light"]
        },
        subtitle2: {
            fontWeight: 600,
            fontSize: "18px",
            color: styles["primary_light"]
        },
        body1: {
            fontWeight: 500,
            fontSize: "17px", 
            color: styles["primary_light"]
        },
        body2: {
            fontWeight: 500,
            fontSize: "15px",
            color: styles["primary_light"]
        },
        button: {
            fontWeight: 700,
            fontSize: "16px",
            color: styles["primary_light"]
        },
        caption: {
            fontWeight: 500,
            fontSize: "15px",
            color: styles["primary_light"]
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "12px",
                    minWidth: "120px",
                    minHeight: "50px"
                },
                text: {
                    backgroundColor: "#00000000",
                    color: styles["primary"],
                    "&:hover": {
                        backgroundColor: styles["primary_light"],
                        color: styles["secondary"],
                    }
                },
                contained: {
                    backgroundColor: styles["secondary_light"],
                    color: styles["primary_light"],
                    "&:hover": {
                        backgroundColor: styles["primary"],
                        color: styles["secondary"],
                    }
                },
                outlined: {
                    borderColor: styles["primary_light"],
                    color: styles["primary_light"],
                    "&:hover": {
                        backgroundColor: styles["primary"],
                        borderColor: styles["secondary"],
                        color: styles["secondary"],
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: "12px",
                    borderColor: "red"
                },
            }
        },
        MuiPopover: {
            styleOverrides: {
                root: {
                    borderRadius: "12px",
                    pointerEvents: 'none',
                },
                paper: {
                    borderRadius: "12px",
                    pointerEvents: "auto",
                },
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    fontSize: "18px"
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: styles["secondary"]
                    }
                },
            }
        }
    }
})