import React, { useEffect, useState } from 'react';

import { 
  Box,
  Button,
  Container, 
  Link, 
  Typography
} from '@mui/material';

import anime from 'animejs/lib/anime.es.js';

import Logo from "../../assets/icons/logo-blue.svg";
import BackgroundClip from "../../assets/videos/background-loop.mp4";

import "./styles.scss";

const LandingPage = () => {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
		var timeLine1 = anime.timeline({
			easing: 'easeOutExpo',
			loop: true
		});

		timeLine1
		.add({
			targets: "#text1",
			keyframes: [
				{ height: "60px", duration: 200 },
				{ height: "0px", duration: 200, delay: 3000 },
			]
		})
		.add({
			targets: "#text2",
			keyframes: [
				{ height: "60px", duration: 200 },
				{ height: "0px", duration: 200, delay: 3000 },
			]
		})
		.add({
			targets: "#text3",
			keyframes: [
				{ height: "60px", duration: 200 },
				{ height: "0px", duration: 200, delay: 3000 },
			]
		})
	}, []);

  return (
    <Box>
      <video
        src={BackgroundClip}
        autoPlay
        loop
        muted
        className='landing__background'
        />
      <Box className='landing__dackdrop'></Box>
      <Box className='landing__top'>
        <Container maxWidth="lg">
          <Box className='flexCenterSBRow'>
            <img
              src={Logo}
              alt="lusid-logo"
              style={{ width: "140px" }}
              />
            <Typography 
              sx={{ color: "white",
                typography: { xs: "h6", sm: "h6", md: "h5", lg: "h5" } }}>
              {"Developers"}
            </Typography>
          </Box>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <Box className="landing flexCenterCenterRow">
          {
            clicked
            ?
            <Box className="landing__content flexCenterCenterColumn" 
              p={"25px"}>
              <Typography
                variant='h4'>
                Coming Soon
              </Typography>
            </Box>
            :
            <Box className="landing__content flexCenterSBColumn" 
              p={"25px"}>
              <Box 
                sx={{ height: "50%", paddingTop: "30px" }} 
                className="flexCenterSBColumn">
                <Box>
                  <Typography
                    textAlign={"center"}
                    sx={{ typography: { xs: "h6", sm: "h6", md: "h5", lg: "h5" } }}>
                    Tools to leverage our Digital Platform.
                  </Typography>
                  <Box sx={{ height: "60px", visibility: { xs: "hidden", sm: "hidden", md: "visible", lg: "visible" } }}>
                    <Typography id="text1" className='landing__text' variant='h5'>Design applications that solve business problems</Typography>
                    <Typography id="text2" className='landing__text' variant='h5'>Rapidly build, test, and deploy applications </Typography>
                    <Typography id="text3" className='landing__text' variant='h5'>Make work better for your organization</Typography>
                  </Box>
                </Box>

                <Box className="flexCenterCenterRow" gap={"20px"}>
                  <Button
                    variant='contained'
                    onClick={() => setClicked(true)}>
                    {"<Start Building />"}
                  </Button>
                </Box>
              </Box>

              <Box className="flexCenterCenterRow">
                <Typography>
                  Have a unique problem that require some brainstorming?&nbsp;
                </Typography>
                <Link
                  href="https://lusid.ai/contact-us"
                  target="_blank" rel="noopener noreferrer">
                  Contact us
                </Link>
              </Box>

            </Box>
          }
        </Box>
      </Container>
    </Box>
  )
}

export default LandingPage;