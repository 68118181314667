import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { 
  Box,
	Container, 
	Typography 
} from '@mui/material';

// import NavBar from '../components/navbar/NavBar';
import Footer from '../components/footer/Footer';

//routes
import LandingPage from '../views/landingPage/LandingPage';
import AuthPage from '../views/authPage/AuthPage';
import HomePage from '../views/homePage/HomePage';

//routes

const MainRoutes = () => {
  return (
    <Router>
			{/* <NavBar /> */}
      <Box>
        <Routes>
          <Route path="/" element={<LandingPage />}/>
          <Route path="/auth" element={<AuthPage />}/>
          <Route path="/code" element={<HomePage />}/>
        
          <Route path="*" element={<NotFoundPage />}/>
        </Routes>
      </Box>
			<Footer />
    </Router>
  )
}

export default MainRoutes;

// 404 page
const NotFoundPage = () => {
  return(
		<Container maxWidth="lg">
      <Box sx={{ height: "calc(100vh)", backgroundColor: "rgb(21, 22, 23)" }} className="flexCenterCenterRow">
			  <Typography variant='h3'>404 - Page not found</Typography>
      </Box>
		</Container>
  )
}