import React from 'react';
// import { useNavigate } from 'react-router-dom';

import {
	Box, 
	Container, 
	Grid, 
	IconButton, 
	Link, 
	Typography 
} from '@mui/material';

import Logo from "../../assets/icons/logo-white.svg";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';

import "./styles.scss";

const Footer = () => {
	// const navigate = useNavigate();

	// const handleNavigate = (route) => {
	// 	handleGoTop();
	// 	navigate(route);
	// }

	const handleGoTop = () => {
		document.body.scrollTop = 0; // For Safari
  	document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}

  return (
    <Box className="footer">
      <Container maxWidth="lg">
				<Box>
					<Box sx={{ paddingTop: "40px", paddingBottom: "50px" }}>
						<img
							src={Logo}
							alt='lusid-footer-logo'
							style={{ width: "250px" }}
							/>
					</Box>

					<Grid container 
						spacing={2}
						rowSpacing={6}
						className="flexFSSBRow" 
						sx={{ paddingTop: "40px", paddingBottom: "50px" }}>	
						<Grid item xs={12} sm={6} md={"auto"} lg={"auto"}>
							<Typography variant='h6' className='footer__heading'>Products</Typography>
							<Link
								href="https://lusid.ai/products#web-app"
								target="_blank" rel="noopener noreferrer"
								sx={{ textDecoration: "none" }}
								>
								<Typography variant='body2' className='footer__item'>Lusid Web App</Typography>
							</Link>
							<Link
								href="https://lusid.ai/products#mobile-app"
								target="_blank" rel="noopener noreferrer"
								sx={{ textDecoration: "none" }}
								>
								<Typography variant='body2' className='footer__item'>Lusid Mobile App</Typography>
							</Link>
							<Link
								href="https://lusid.ai/products#extension"
								target="_blank" rel="noopener noreferrer"
								sx={{ textDecoration: "none" }}
								>
								<Typography variant='body2' className='footer__item'>Lusid Extension</Typography>
							</Link>
						</Grid>
						{/* <Box>
							<Typography variant='h6' className='footer__heading'
								onClick={() => handleNavigate("/capabilities")}>Capabilities</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#instant-payment")}>Instant Payment</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#issue-virtual-card")}>Issue Virtual Card</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#tap-to-pay")}>Tap to Pay</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#scan-to-pay")}>Scan to Pay</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#control")}>Controls & Compliance</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#easy-bill-capture")}>Easy Bill Capture</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#pay-circle")}>Pay Circle</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#automation")}>Spend Automation</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#vendor")}>Payee/Vendor Management</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#bio")}>Biometrics Payment</Typography>
						</Box> */}
						{/* <Box>
							<Typography variant='h6' className='footer__heading'
								onClick={() => handleNavigate("/#solutions")}>Solutions</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/#solutions")}>Solutions Overview</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/#solutions")}>Never miss a bill</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/#solutions")}>Control & reconcile spend</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/#solutions")}>Policy Compliance</Typography>
						</Box> */}
						{/* <Box>
							<Typography variant='h6' className='footer__heading'
								onClick={() => handleNavigate("/#developers")}>Developers</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/#developers")}>Developer Portal</Typography>
						</Box> */}
           	<Grid item xs={12} sm={6} md={"auto"} lg={"auto"}>
              <Typography variant='h6' className='footer__heading'>Company</Typography>
							<Link
								href="https://lusid.ai/about-us"
								target="_blank" rel="noopener noreferrer"
								sx={{ textDecoration: "none" }}
								>
								<Typography variant='body2' className='footer__item'>About us</Typography>
							</Link>
							<Link
								href="https://lusid.ai/contact-us"
								target="_blank" rel="noopener noreferrer"
								sx={{ textDecoration: "none" }}
								>
								<Typography variant='body2' className='footer__item'>Contact us</Typography>
							</Link>
						</Grid>
						<Grid item xs={12} sm={6} md={"auto"} lg={"auto"}>
							<Typography variant='h6' className='footer__heading'>Legal</Typography>
							<Link
								href="https://lusid.ai/terms-and-conditions"
								target="_blank" rel="noopener noreferrer"
								sx={{ textDecoration: "none" }}
								>
								<Typography variant='body2' className='footer__item'>Terms and Conditions</Typography>
							</Link>
							<Link
								href="https://lusid.ai/security"
								target="_blank" rel="noopener noreferrer"
								sx={{ textDecoration: "none" }}
								>
								<Typography variant='body2' className='footer__item'>Security</Typography>
							</Link>
							<Link
								href="https://lusid.ai/privacy-policy"
								target="_blank" rel="noopener noreferrer"
								sx={{ textDecoration: "none" }}
								>
								<Typography variant='body2' className='footer__item'>Privacy</Typography>
							</Link>		
						</Grid>
					</Grid>

					<Box className="flexCenterSBRow">
						<IconButton onClick={handleGoTop}>
							<ArrowUpwardIcon fontSize='large' sx={{ color: "white"}} />
						</IconButton>

						<Box sx={{ paddingTop: "40px", paddingBottom: "50px" }}>
							<IconButton>
								<LinkedInIcon fontSize='large' sx={{ color: "white"}}/>
							</IconButton>
							<IconButton>
								<XIcon fontSize='large' sx={{ color: "white"}}/>
							</IconButton>
						</Box>
					</Box>
				</Box>
			</Container>
    </Box>
  )
}

export default Footer;